import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Card,
  Icon,
  Stack,
  TextStyle,
  TextField,
  Tooltip,
  Button,
  FormLayout,
  Heading,
  Select,
} from "@shopify/polaris";
import {
  CircleInformationMajor,
  TickMinor,
  MobileCancelMajor,
} from "@shopify/polaris-icons";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "antd";
import parse from "html-react-parser";
import { NumberFormat } from "../../../utils/Formats";
import { addShoppingCartArticles } from "../../../actions/ShoppingCartActions";
import {
  getMaxSelectabledProducts,
  getProductValidations,
} from "../../../utils/orderValidations";
import { getDiscountsShoppingCart } from "../../../reducers";
import { getImageVideoUrl } from "../../../utils/productValidators";

const ItemDetail = ({
  itemData: itemDataD,
  almacenId,
  discounts,
  showTax,
  onChangeVariant,
  setQuantityError = () => {},
  quantityError,
  canShowStock,
  clientCurrency = {
    id: 1,
    nombre: "Moneda nacional",
    claveFiscal: "MXN",
    simbolo: "$",
    tipoCambio: 1,
  },
  currencies = [],
}) => {
  const dispatch = useDispatch();
  const { discounts: discountsForProduct = [], priceToUse = null } =
    useSelector(getDiscountsShoppingCart);
  const onChangeOptionRef = useRef(null);
  const [itemData, setItemData] = useState({
    ...itemDataD,
    existencia: itemDataD.existencia.filter(
      (i) => Number(i.almacen_id) === Number(almacenId)
    ),
  });
  const minimum = useRef(itemData.minimum ? itemData.minimum : 1);
  const factorVenta = useRef(itemData.factor_venta ? itemData.factor_venta : 1);
  const [percentage, setPercentage] = useState(0);
  const [precio, setPrecio] = useState(0);
  const [quantity, setQuantity] = useState(String(minimum.current));
  const [variantSelected, setVariantSelected] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    option5: "",
    option6: "",
    option7: "",
    option8: "",
    option9: "",
    option10: "",
  });
  const [tooltipVisible, setTooltipVisible] = useState(true);
  const [fieldsError, setFieldsError] = useState(null);
  const [precioEmpresa, setPrecioEmpresa] = useState();
  const [currency, setCurrency] = useState();

  const { descuentoPromocion, descuentoVolumen, descuentoMaximo } = discounts;

  useEffect(() => {
    onChangeOptionRef.current = onChangeOption;
  });

  useEffect(() => {
    onChangeOptionRef.current();
  }, []);

  const getCurrencyConvertion = useCallback(
    (price) => {
      if (precioEmpresa && currency) {
        const moneda = precioEmpresa?.moneda;
        const tipoCambio = currency?.tipoCambio;

        if (moneda) {
          if (clientCurrency.claveFiscal === "MXN") {
            return price * tipoCambio;
          } else if (moneda === "MXN") {
            return price / tipoCambio;
          } else {
            return price;
          }
        }
        return 0;
      }
      return 0;
    },
    [clientCurrency.claveFiscal, currency, precioEmpresa]
  );

  function onChangeOption(value, option) {
    let {
      existencia = [],
      imagen = [],
      precios,
      unidadmed = "",
      nombre = "",
      _id,
      linea_articulo = "",
      options = [],
      variants = [],
      isParent,
      impuestos = [],
      sku = "",
    } = itemData;

    // VALIDATION FOR VARIANTS
    if (isParent) {
      let firstOptions = {};

      if (value && option) {
        firstOptions = { ...variantSelected };
        firstOptions[option] = value;
      } else {
        options.forEach(
          (i, idx) => (firstOptions[`option${idx + 1}`] = i.values[0])
        );
      }

      setVariantSelected(firstOptions);

      let matches = [];

      for (const key in firstOptions) {
        if (Object.hasOwnProperty.call(firstOptions, key)) {
          const element = firstOptions[key];
          let matched = [];
          if (matches.length === 0) {
            matched = variants.filter(
              (i) => i.hasOwnProperty(key) && i[key] === element
            );
          } else {
            matched = matches.filter(
              (i) => i.hasOwnProperty(key) && i[key] === element
            );
          }
          matches = matched;
        }
      }

      const {
        variantId: {
          _id: v_Id = "",
          nombre: vNombre = "",
          existencia: vExistencia = [],
          imagen: vImagen = [],
          precios: vPrecios = [],
          unidadmed: vUnidadmed = "",
          linea_articulo: vLinea_articulo = "",
          impuestos: vImpuestos = [],
          sku: vSku = "",
        },
      } = matches[0];

      nombre = vNombre;
      existencia = vExistencia;
      imagen = vImagen;
      precios = vPrecios;
      unidadmed = vUnidadmed;
      linea_articulo = vLinea_articulo;
      impuestos = vImpuestos;
      _id = v_Id;
      sku = vSku;

      // Validate existence for assigned almacen_id
      existencia = almacenId
        ? existencia.filter((i) => Number(i.almacen_id) === Number(almacenId))
        : existencia;

      setItemData((s) => ({
        ...s,
        _id,
        id: _id,
        nombre: nombre,
        existencia: existencia,
        imagen: imagen,
        precios: precios,
        unidadmed: unidadmed,
        impuestos: impuestos,
        sku: sku,
      }));

      onChangeVariant(vImagen, existencia);
    } else {
      onChangeVariant(imagen, existencia);
    }

    const { percentDefault, price } = getProductValidations({
      product: {
        _id,
        precios,
        linea_articulo,
      },
      descMaximos: descuentoMaximo,
      descPromoAplicada: descuentoPromocion,
      descVolumenAplicada: descuentoVolumen,
      discounts: discountsForProduct,
      priceToUse,
    });

    setPercentage(percentDefault);
    setPrecio(price);

    // Valida si el articulo tiene un mínimo y un factor de venta
    if (
      !itemData.minimum ||
      (itemData.minimum === 1 && itemData?.factor_venta === 1)
    ) {
      setTooltipVisible(false);
    }

    const precioEmpresa =
      precios.find(
        ({ precio_empresa_id }) => precio_empresa_id === priceToUse
      ) || precios.find(({ precio_empresa_id }) => precio_empresa_id === 42);

    setPrecioEmpresa(precioEmpresa);

    const currency = currencies.find(
      ({ label }) => label === precioEmpresa?.moneda
    );

    setCurrency(currency);
  }

  const quantityValidators = useCallback(
    (value) => {
      if (value < minimum.current) {
        setFieldsError(`Mínimo ${minimum.current}`);
        setQuantityError(true, itemData._id);
        return;
      }

      if (value > itemData.maximum) {
        if (itemData.maximum && itemData.maximum > 0) {
          setFieldsError(`Máximo ${itemData.maximum}`);
          setQuantityError(true, itemData._id);
        } else {
          setFieldsError("");
          setQuantityError(false, itemData._id);
        }
        if (value % factorVenta.current !== 0) {
          setFieldsError(`Incrementos de ${factorVenta.current}`);
          setQuantityError(true, itemData._id);
          return;
        }
        return;
      }

      if (value % factorVenta.current !== 0) {
        setFieldsError(`Incrementos de ${factorVenta.current}`);
        setQuantityError(true, itemData._id);
        return;
      }

      setFieldsError("");
      setQuantityError(false, itemData._id);
    },
    [itemData._id, itemData.maximum, setQuantityError]
  );

  function handleFieldChange(value) {
    if (value === "0") {
      // Change this
      setQuantity("1");
    }
    setQuantity(value);
    quantityValidators(value);
  }

  function getDiscount() {
    let discount =
      percentage > 0 ? precio - precio * (percentage / 100) : precio;

    return discount;
  }

  function validatePriceWithTax() {
    let price = getDiscount();
    let impuestos = itemData.impuestos?.filter((j) => j.porcentaje > 0) || [];
    let summary = 0;
    impuestos.forEach((j) => (summary = summary + 1 * (j.porcentaje / 100)));
    const tax = 1 + summary;
    price = showTax ? price * tax : price;
    price = getCurrencyConvertion(price);
    price = Math.round((price + Number.EPSILON) * 100) / 100;

    return Number(price || 0).toLocaleString("en", NumberFormat);
  }

  function getPercentage() {
    // Porcentaje de descuento, sólo para mostrarlo al cliente en la interfaz
    let percent = (1 - getDiscount() / precio) * 100;
    return Math.round((percent + Number.EPSILON) * 100) / 100;
  }

  function validatePriceWithTaxMain() {
    let price = precio;
    let impuestos = itemData.impuestos?.filter((j) => j.porcentaje > 0) || [];
    let summary = 0;
    impuestos.forEach((j) => (summary = summary + 1 * (j.porcentaje / 100)));
    const tax = 1 + summary;
    price = showTax ? price * tax : price;
    price = getCurrencyConvertion(price);
    price = Math.round((price + Number.EPSILON) * 100) / 100;
    return Number(price || 0).toLocaleString("en", NumberFormat);
  }

  const onBlurTextField = (value) => {
    quantityValidators(value);

    const existencia = itemData.existencia.reduce(
      (prev, current) => prev + current.existencia,
      0
    );

    if (String(quantity) === "" || Number(quantity) <= 0) {
      setQuantity("1");
      return;
    }

    if (existencia > 0 && !itemData.continuar_vendiendo) {
      if (Number(quantity) > existencia) setQuantity(String(existencia));
      return;
    }
  };
  function onAddArticle() {
    const itemToAdd = {
      article: itemData,
      cantidad: Number(quantity),
      descuentoMaximo,
      descuentoVolumen,
      descuentoPromocion,
      showTax,
    };

    notification.open({
      message: <TextStyle>Producto agregado en tu pedido</TextStyle>,
      description: (
        <div className="flex flex-row mt-4">
          <img
            alt=""
            style={{ width: 70, height: 70 }}
            src={
              getImageVideoUrl(itemData?.imagen, "image", false) ||
              "/Default Photo.png"
            }
          />
          <div className="flex flex-col ml-6">
            <TextStyle>{itemData.nombre}</TextStyle>
            <TextStyle>
              {itemData.isParent ? itemData.sku || "" : itemData.clave}
            </TextStyle>
          </div>
        </div>
      ),
      duration: 1.5,
      icon: <Icon source={TickMinor} />,
      closeIcon: <Icon source={MobileCancelMajor} />,
    });

    setQuantity("1");
    dispatch(addShoppingCartArticles(itemToAdd));
  }

  let {
    existencia = [],
    isParent = false,
    unidadmed = "",
    continuar_vendiendo,
    options = [],
    nombre = "",
    descripcion = "",
  } = itemData;

  return (
    <Card sectioned>
      <FormLayout>
        <Heading>{nombre}</Heading>
        <Stack>
          {getPercentage() > 0 ? (
            <div className="flex">
              <div className="mr-6 line-through">
                <TextStyle variation="subdued">
                  ${validatePriceWithTaxMain()}
                </TextStyle>
              </div>
              <TextStyle variation="subdued">
                {" "}
                {`(${getPercentage()}%)`}
              </TextStyle>
            </div>
          ) : null}
          <TextStyle variation={getPercentage() > 0 ? "negative" : null}>
            ${validatePriceWithTax()}
          </TextStyle>
        </Stack>
        <Stack vertical>
          {existencia
            .filter((i) => String(i.almacen_id) === String(almacenId))
            .map((i, index) => (
              <div key={index}>
                {i?.existencia > 0 ? (
                  <TextStyle>
                    {canShowStock
                      ? `${i?.existencia} disponibles `
                      : "Disponible "}
                    en
                  </TextStyle>
                ) : (
                  <TextStyle>Bajo Pedido en</TextStyle>
                )}
                <TextStyle variation="strong"> {i?.nombre_almacen}</TextStyle>
              </div>
            ))}
        </Stack>
        {isParent && (
          <Stack distribution="fill" alignment="fill">
            {options.map((i, idx) => (
              <Select
                key={idx}
                label={i.name}
                options={i.values.map((j) => ({ label: j, value: j }))}
                onChange={(value) => onChangeOption(value, `option${idx + 1}`)}
                value={variantSelected[`option${idx + 1}`]}
              />
            ))}
          </Stack>
        )}
        <div className="flex items-start">
          <div className="flex-1 mr-6">
            <TextField
              type="number"
              min={1}
              max={getMaxSelectabledProducts(
                existencia,
                itemData?.maximum,
                continuar_vendiendo
              )}
              value={String(quantity)}
              onChange={handleFieldChange}
              onBlur={() => onBlurTextField(Number(quantity))}
              suffix={
                <div className="flex items-center">
                  {unidadmed ? unidadmed : ""}
                  {tooltipVisible && (
                    <div className="tooltip-space cursor-pointer">
                      <Tooltip
                        content={
                          <>
                            <p>Mínimo: {minimum.current}</p>
                            <p>
                              Máximo:{" "}
                              {itemData.maximum === 0
                                ? "Ilimitado"
                                : itemData.maximum}
                            </p>
                            <p>Factor: {factorVenta.current}</p>
                          </>
                        }
                        preferredPosition="mostSpace"
                      >
                        <Icon
                          source={CircleInformationMajor}
                          // color="inkLightest"
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>
              }
              error={fieldsError}
            />
          </div>
          <div className="flex-1">
            <Button
              primary
              fullWidth
              onClick={onAddArticle}
              disabled={
                Number(validatePriceWithTaxMain()) === 0 || quantityError
              }
            >
              Agregar
            </Button>
          </div>
        </div>
        <>
          {parse(descripcion, {
            htmlparser2: { recognizeCDATA: true },
            trim: false,
          })}
        </>
      </FormLayout>
    </Card>
  );
};

export default ItemDetail;
