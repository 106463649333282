import {
  Card,
  DropZone,
  FormLayout,
  Modal,
  Spinner,
  TextStyle,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { ZoomImage } from "..";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { NormalizeName } from "../../utils/Formats";

export default function ImageModal({
  isOpen = false,
  onClose,
  images = [],
  onAddImage,
  onSelectImage,
  imageVariant = [],
}) {
  const [allImages, setAllImages] = useState(images);
  const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];

  const [selectedImages, setSelectedImages] = useState([]);
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    const activeImage = allImages.find((i) => i === imageVariant[0]);
    if (activeImage) {
      handleSelectImage(true, activeImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images, imageVariant, allImages]);

  useEffect(() => {
    if (imageVariant.length > 0) {
      const existImage = allImages.find((i) => i === imageVariant[0]);
      if (!existImage) {
        setAllImages(allImages.concat(imageVariant));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSelectImage(value, image) {
    if (value) {
      if (!selectedImages.some((i) => i === image)) {
        setSelectedImages([image]);
      }
    } else {
      setSelectedImages(selectedImages.filter((i) => i !== image));
    }
  }

  function handleDropZoneDrop(_dropFiles, acceptedFiles = []) {
    setIsAdding(true);
    const formData = new FormData();
    if (acceptedFiles.length > 0) {
      acceptedFiles.forEach((file) => {
        formData.append("files", file, NormalizeName(file.name));
      });

      ApiServiceComercios.postFiles(formData)
        .then(({ files = [] }) => {
          const urls = files.map(({ url }) => url);
          setAllImages(allImages.concat(urls));
          urls.forEach((url) => {
            handleSelectImage(true, url);
            onSelectImage(urls.length > 0 ? urls[0] : "");
          });
        })
        .finally(() => {
          setIsAdding(false);
        });
    }
  }

  const fileUpload = <DropZone.FileUpload />;

  return (
    <Modal
      open={isOpen}
      title="Actualizar imagen de variante"
      onClose={onClose}
      primaryAction={{
        content: "Listo",
        onAction: () => {
          onAddImage(selectedImages);
          onClose();
        },
      }}
      secondaryActions={[
        {
          content: "Cancelar",
          onAction: onClose,
        },
      ]}
    >
      <Card sectioned>
        <FormLayout>
          <TextStyle variation="subdued">
            Solo puedes seleccionar imágenes como elementos multimedia de
            variantes
          </TextStyle>
          <ZoomImage
            images={allImages}
            selectedImages={selectedImages}
            handleSelectImage={handleSelectImage}
          >
            <div className="flex flex-row w-full">
              <DropZone
                type="image"
                labelHidden
                outline
                accept={validImageTypes.join(",")}
                onDrop={handleDropZoneDrop}
                allowMultiple
                disabled={isAdding}
                overlay
              >
                {isAdding ? <Spinner size="small" /> : fileUpload}
              </DropZone>
            </div>
          </ZoomImage>
        </FormLayout>
      </Card>
    </Modal>
  );
}
