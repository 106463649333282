// Librerías externas
import React, { useState, useEffect, useCallback, useRef } from "react";
import _, { sum } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  TextField,
  Button,
  Banner,
  List,
  Collapsible,
  Icon,
  FormLayout,
  Spinner,
} from "@shopify/polaris";
import { ChevronDownMinor, ChevronUpMinor } from "@shopify/polaris-icons";
import { Drawer } from "antd";
import { useHistory } from "react-router-dom";

// Módulos del proyecto
import {
  getArticlesShoppingCart,
  getCustomerData,
  getDiscountsShoppingCart,
  getOrderInfo,
  getShippingFees,
} from "../../../reducers";
import {
  addClientInfo,
  addNoteItemCart,
  changeQuantity,
  clearShoppingCartNoUser,
  removeArticleShoppingCart,
} from "../../../actions/ShoppingCartActions";
import { ArticuloItem, ClientSection, EditContactDirectionModal } from "../..";
import ApproveCard from "../../ApproveCard";
import { toggleToast } from "../../../actions/InteractiveActions";
import ApiServiceComercios from "../../../services/ApiServiceComercios";
import useAbility from "../../../hooks/useAbility";
import { VendorAutocomplete } from "../Index";
import { scrollToTop } from "../../../utils/windowActions";

// Estilos y CSS
import "../../SectionsStyled/SectionsStyled.css";
import "./ShoppingCart.css";
import useToggle from "../../../hooks/useToggle";

const ShoppingCart = ({
  onCloseCart,
  isCartVisible = true,
  currency,
  currencies,
  modeView = "online_store",
}) => {
  const dispatch = useDispatch();
  const router = useHistory();
  const shippingFees = useSelector(getShippingFees);

  const {
    articles,
    descuentoMaximo,
    descuentoVolumen,
    descuentoPromocion,
    descuentoAplicado,
    showTax,
    isActivePayment,
    usePaymentsInOrders,
  } = useSelector(getArticlesShoppingCart);
  const {
    clienteEnvio,
    limiteCredito,
    impuesto: impuestoDB,
  } = useSelector(getOrderInfo);
  const { discounts = [], priceToUse = null } = useSelector(
    getDiscountsShoppingCart
  );
  const selectArticulosRef = useRef(null);
  const [isCreating, setIsCreating] = useState(false);
  const [totales, setTotales] = useState([]);
  const [selectedArticulos, setSelectedArticulos] = useState([]);
  const [articleCount, setArticleCount] = useState([]);
  const [note, setNote] = useState("");
  const clienteDB = useSelector(getCustomerData);
  const [errorArticulos, setErrorArticulos] = useState({
    message: "",
    articulos: [],
  });
  const [verifyLimitExceeded, setVerifyLimitExceeded] = useState(false);
  const [quantityError, setQuantityError] = useState([]);
  const isForbiddenReadPedidos = useAbility("read", "Pedidos");
  const [activeCollapsible, setActiveCollapsible] = useState(1);
  const [isOpenModal, toggleAddressModal] = useToggle(false);
  const [editDirectionName, setEditDirectionName] = useState("envío");
  const [isEditingDirection, setIsEditingDirection] = useState(false);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [vendorInputValue, setVendorInputValue] = useState("");
  const [selectedShippmentFee, setSelectedShippmentFee] = useState();
  const [isShowingFees, setIsShowingFees] = useState(false);

  function selectArticulos(items) {
    return new Promise((resolve) => {
      let merged = _.unionBy(selectedArticulos, items, "_id");
      // dispatch(clearArticulos());
      setSelectedArticulos(merged);
      setArticleCount((state) => [
        ...new Set(
          state.concat(
            items.map((item) => ({
              id: item._id,
              precio: item.precios[0]?.precio || 0,
              cantidad: item.cantidad ? item.cantidad : "1",
              total:
                (item.cantidad ? item.cantidad : 1) *
                (item.precios[0]?.precio || 1),
            }))
          )
        ),
      ]);
      resolve(true);
    });
  }

  useEffect(() => {
    selectArticulosRef.current = selectArticulos;
  });

  useEffect(() => {
    selectArticulosRef.current(articles);
  }, [articles]);

  useEffect(() => {
    ApiServiceComercios.obtenerPreferenciasComercio().then(
      ({ preferencia }) => {
        setVerifyLimitExceeded(preferencia.verifyLimitExceeded);
      }
    );
  }, [setVerifyLimitExceeded]);

  useEffect(() => {
    if (clienteDB?.vendedor?._id) {
      setSelectedVendors([clienteDB?.vendedor?._id]);
      setVendorInputValue(clienteDB?.vendedor?._id);
    }
  }, [clienteDB?.vendedor?._id]);

  function onChangeArticleValue(value, id, total) {
    let art = [...articleCount];
    art.forEach((obj) => {
      if (obj.id === id) {
        obj.cantidad = value;
      }
    });
    setArticleCount(art);
    setTotales(totales.map((i) => (i.articuloId === id ? { ...i, total } : i)));
    dispatch(
      changeQuantity({ articuloId: String(id), cantidad: Number(value) })
    );
  }

  function onRemoveArticulo(id) {
    let articulosIds = articleCount.filter((item) => item.id !== id);
    let articulos = selectedArticulos.filter((item) => item._id !== id);
    setSelectedArticulos(articulos);
    setArticleCount(articulosIds);
    setTotales(totales.filter((i) => i.articuloId !== id));

    dispatch(removeArticleShoppingCart({ articuloId: id }));
  }

  function addNoteToArticle(note, id) {
    const selectedArt = [...selectedArticulos];
    selectedArt.forEach((obj) => {
      if (obj.id === id) {
        obj["notas"] = note;
      }
    });
    setSelectedArticulos(selectedArticulos);
    dispatch(addNoteItemCart({ note, id }));
  }

  const willReturnInitialData = useCallback((totalArt, articuloId) => {
    if (totalArt && totalArt > 0) {
      setTotales((prevState) =>
        prevState.find((i) => i.articuloId === articuloId)
          ? prevState.map((i) =>
              i.articuloId === articuloId ? { ...i, total: totalArt } : i
            )
          : [...prevState, { total: totalArt, articuloId }]
      );
    }
  }, []);

  function handleSubTotalProductos() {
    return totales.reduce((prev, current) => prev + current.total, 0);
  }

  function handleImpuesto() {
    // Precio de envío
    const clientePrecioEnvio = clienteEnvio?.precio || 0;

    // Impuesto del comercio
    const impuesto = impuestoDB?.impuesto || 16;

    // Valida si se va a cobrar impuesto en el costo de envío
    const cobrarEnvio = impuestoDB?.cobrarEnvio || false;

    // Obtiene los impuestos de los artículos seleccionados
    const totalesWithArticle = totales?.map((i) => ({
      ...i,
      impuestos: articles?.find((j) => j._id === i.articuloId)?.impuestos,
    }));
    // Calcula los impuestos al producto y los agrega a subTotalEachProduct
    let subTotalEachProduct = [];
    totalesWithArticle.forEach((i) => {
      const total = i.total;
      const impuestos = i.impuestos?.filter((j) => j.porcentaje > 0) || [];
      let summa = 0;
      impuestos.forEach((j) => (summa = summa + total * (j.porcentaje / 100)));
      subTotalEachProduct.push(
        Math.round((summa + Number.EPSILON) * 100) / 100
      );
    });

    // Calcula el impuesto aplicado al precio de envío
    let precioEnvio = 0;
    if (selectedShippmentFee) {
      const priceShippingFee = shippingFees.find(
        (i) => i._id === selectedShippmentFee
      ).price;
      precioEnvio = cobrarEnvio
        ? priceShippingFee * (impuesto / 100)
        : priceShippingFee - handlePrecioEnvio();
    } else {
      // Valida el impuesto aplicado al precio de envío
      precioEnvio = cobrarEnvio
        ? clientePrecioEnvio * (impuesto / 100)
        : clientePrecioEnvio - handlePrecioEnvio();
    }

    // Regresa la suma de los impuestos de los productos y el precio de envío
    return sum(subTotalEachProduct) + precioEnvio;
  }

  function handlePrecioEnvio() {
    // Precio de envío
    const clientePrecioEnvio = clienteEnvio?.precio || 0;
    // Impuesto del comercio
    const impuesto = impuestoDB?.impuesto || 16;
    // Valida si se va a cobrar impuesto en el costo de envío
    const cobrarEnvio = impuestoDB?.cobrarEnvio || false;
    // Calcula el costo de envío sin impuesto aplicado
    let costoEnvio = 0;
    if (selectedShippmentFee) {
      const priceShippingFee = shippingFees.find(
        (i) => i._id === selectedShippmentFee
      ).price;

      costoEnvio = cobrarEnvio
        ? priceShippingFee
        : (1 * priceShippingFee) / (1 + impuesto / 100);
    } else {
      costoEnvio = cobrarEnvio
        ? clientePrecioEnvio
        : (1 * clientePrecioEnvio) / (1 + impuesto / 100);
    }

    return costoEnvio;
  }

  function handleTotal() {
    const subTotalPedido = handleSubTotalProductos();
    const precioEnvio = handlePrecioEnvio();
    const impuesto = handleImpuesto();

    let sumTotal = sum([subTotalPedido, precioEnvio, impuesto]);

    return sumTotal;
  }

  function calculateAvailable() {
    return (
      (limiteCredito.limite_credito || 0) -
      (limiteCredito.saldo?.actual || 0) -
      handleTotal()
    );
  }

  function getOrderData() {
    let shippingFeeId = null;

    if (selectedShippmentFee) {
      const priceShippingFee = shippingFees.find(
        (i) => i._id === selectedShippmentFee
      );
      shippingFeeId = priceShippingFee._id;
    }

    const articulos = selectedArticulos.map((ar) => ({
      articulo: ar._id,
      notas: ar.notas || "",
      cantidad: Number(articleCount.find((art) => art.id === ar._id).cantidad),
    }));
    const data = {
      articulos,
      notas: note,
      vendorId: vendorInputValue,
      shippingFeeId,
    };

    return {
      orderData: data,
      quotationId: null,
      draftOrderId: null,
    };
  }

  function crearPedido() {
    setIsCreating(true);
    dismissWarning();

    let shippingFeeId = null;

    if (selectedShippmentFee) {
      const priceShippingFee = shippingFees.find(
        (i) => i._id === selectedShippmentFee
      );
      shippingFeeId = priceShippingFee._id;
    }

    const articulos = selectedArticulos.map((ar) => ({
      articulo: ar._id,
      notas: ar.notas || "",
      cantidad: Number(articleCount.find((art) => art.id === ar._id).cantidad),
    }));
    const data = {
      articulos,
      notas: note,
      vendorId: vendorInputValue,
      shippingFeeId,
    };
    ApiServiceComercios.crearPedido(data, null)
      .then((result) => {
        if (result.ok) {
          onCloseCart();
          dispatch(toggleToast({ message: result.message }));
          dispatch(clearShoppingCartNoUser());
          router.push(`/cliente/pedidos/${result.id}/gracias`);
        } else {
          setIsCreating(false);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          setErrorArticulos({
            message: err.response?.data?.message || "",
            articulos: err.response?.data?.articulos || [],
          });
        }
        scrollToTop();
        setIsCreating(false);
      });
  }

  function handleSaveDirection(directionEdited) {
    setIsEditingDirection(true);
    toggleAddressModal();
    const direction = clienteDB.direcciones.find(
      (i) => i.consignatario_id === directionEdited.consignatario_id
    );
    const dirIndex = clienteDB.direcciones.findIndex(
      (i) => i.consignatario_id === directionEdited.consignatario_id
    );
    const isEqual = _.isEqual(directionEdited, direction);
    let willUpdate = false;
    if (!isEqual) {
      willUpdate =
        String(directionEdited.consignatario).trim() ===
        String(direction.consignatario).trim();
    }
    // Si willUpdate = true, va a actualizar el consignatario, sino va a crear un nuevo registro
    ApiServiceComercios.actualizaDireccionCliente({
      ...directionEdited,
      willUpdate,
      cliente_id: clienteDB._id,
      editDirectionName,
    })
      .then(({ consignatario_id, message }) => {
        let direcciones = clienteDB.direcciones;
        if (willUpdate) {
          direcciones[dirIndex] = directionEdited;
        } else {
          direcciones.push({
            ...directionEdited,
            consignatario_id:
              consignatario_id || directionEdited.consignatario_id,
          });
        }
        const direccionesId = {};
        direccionesId[
          editDirectionName === "envío"
            ? "direccionEnvioId"
            : "direccionFacturacionId"
        ] = willUpdate ? directionEdited.consignatario_id : consignatario_id;
        dispatch(toggleToast({ message }));

        dispatch(
          addClientInfo({
            customerData: {
              direcciones,
              direccionesId,
            },
          })
        );
      })
      .finally(() => {
        setIsEditingDirection(false);
      });
  }

  function dismissWarning() {
    setErrorArticulos({
      message: "",
      articulos: [],
    });
  }

  function handleChangeCollapsible(id) {
    setActiveCollapsible(id === activeCollapsible ? null : id);
  }

  const isDisabled =
    (!isForbiddenReadPedidos && selectedArticulos.length === 0) ||
    isCreating ||
    (verifyLimitExceeded && calculateAvailable() < 0) ||
    !clienteDB.almacen ||
    !clienteDB.clave ||
    quantityError.length ||
    isEditingDirection ||
    (isShowingFees && !selectedShippmentFee) ||
    ["V", "C"].includes(clienteDB.status_microsip);

  const footer = (
    <>
      {modeView === "online_store" ? (
        <div className="w-full mt-4 mb-10">
          <button
            className={`w-full font-bold text-white text-center flex justify-center ${
              isDisabled ? "bg-gray-400 cursor-none" : "bg-black"
            }`}
            style={{
              paddingTop: 15,
              paddingBottom: 15,
            }}
            onClick={crearPedido}
            disabled={isDisabled}
          >
            {isEditingDirection ? (
              <div className="loading-spinner">
                <Spinner size="small" />
              </div>
            ) : (
              "Crear pedido"
            )}
          </button>
        </div>
      ) : (
        <div className="mt-10 mx-8">
          <Button
            fullWidth
            primary
            onClick={crearPedido}
            disabled={isDisabled}
            loading={isCreating}
          >
            Crear pedido
          </Button>
        </div>
      )}
    </>
  );

  function calcularPesoTotal() {
    let weight = 0;
    for (let i = 0; i < selectedArticulos.length; i++) {
      const articulo = selectedArticulos[i];
      const cantidad =
        articleCount.find((art) => art.id === articulo._id)?.cantidad || 1;
      weight += (articulo?.peso || 0) * cantidad;
    }
    return weight;
  }

  const weight = calcularPesoTotal();

  return (
    <>
      {isOpenModal && (
        <EditContactDirectionModal
          direccionEnvio={
            clienteDB.direcciones.find(
              (i) => i.consignatario_id === clienteDB.direccionEnvioId
            ) || {}
          }
          direccionFacturacion={
            clienteDB.direcciones.find(
              (i) => i.consignatario_id === clienteDB.direccionFacturacionId
            ) || {}
          }
          title={editDirectionName}
          direcciones={clienteDB?.direcciones}
          isOpen={isOpenModal}
          handleChange={toggleAddressModal}
          handleSave={handleSaveDirection}
        />
      )}
      <Drawer
        className="menu-drawer-shopping-cart menu-drawer"
        title="Tu pedido"
        placement="right"
        closable
        onClose={onCloseCart}
        open={isCartVisible}
        footer={!isForbiddenReadPedidos ? footer : null}
      >
        {errorArticulos.message && (
          <div className="mb-8">
            <Banner
              title={errorArticulos.message}
              status="warning"
              onDismiss={dismissWarning}
            >
              <List>
                {errorArticulos?.articulos?.map((i, key) => (
                  <List.Item key={key}>{i.nombre}</List.Item>
                ))}
              </List>
            </Banner>
          </div>
        )}
        <div className="px-6 border-b border-gray-300">
          <div
            className="w-full py-8 cursor-pointer"
            onClick={() => handleChangeCollapsible(1)}
          >
            <Button plain removeUnderline>
              <div className="flex items-center text-blue-500 gap-2">
                <Icon
                  source={
                    activeCollapsible === 1 ? ChevronUpMinor : ChevronDownMinor
                  }
                />
                Detalles del pedido
              </div>
            </Button>
          </div>
          <Collapsible open={activeCollapsible === 1}>
            {selectedArticulos.length > 0 && (
              <Card.Section>
                {selectedArticulos.map((article, idx) => (
                  <ArticuloItem
                    key={idx}
                    descMaximos={descuentoMaximo}
                    descVolumenAplicada={descuentoVolumen}
                    descPromoAplicada={descuentoPromocion}
                    descAplicado={descuentoAplicado}
                    runOnInsertBegin={willReturnInitialData}
                    articulo={article}
                    quantity={Number(
                      articleCount.find((ar) => ar.id === article._id).cantidad
                    )}
                    selectNote={addNoteToArticle}
                    handleChange={onChangeArticleValue}
                    onRemove={onRemoveArticulo}
                    showTax={showTax}
                    mode="shopping"
                    setQuantityError={(value, id) => {
                      setQuantityError((prevState) =>
                        value
                          ? prevState.some((errorId) => errorId === id)
                            ? prevState
                            : [...prevState, id]
                          : prevState.filter((errorId) => errorId !== id)
                      );
                    }}
                    currencies={currencies}
                    clientCurrency={currency}
                    discounts={discounts}
                    priceToUse={priceToUse}
                  />
                ))}
              </Card.Section>
            )}
          </Collapsible>
        </div>
        <div className="px-6 border-b border-gray-300">
          <div
            className="w-full py-8 cursor-pointer"
            onClick={() => handleChangeCollapsible(2)}
          >
            <Button plain removeUnderline>
              <div className="flex items-center text-blue-500 gap-2">
                <Icon
                  source={
                    activeCollapsible === 2 ? ChevronUpMinor : ChevronDownMinor
                  }
                />
                Envío y facturación
              </div>
            </Button>
          </div>
          <Collapsible open={activeCollapsible === 2}>
            <ClientSection
              clienteDB={clienteDB}
              isLoading={false}
              setIsBlocking={false}
              handleDirectionModal={toggleAddressModal}
              hasRemoveItem={false}
              setEditDirectionName={setEditDirectionName}
            />
            <div className="flex flex-col my-6">
              <FormLayout>
                <TextField
                  type="text"
                  placeholder="Agrega una nota al pedido"
                  value={note}
                  onChange={(text) => setNote(text)}
                />
                <VendorAutocomplete
                  selected={selectedVendors}
                  vendorInputValue={vendorInputValue}
                  setSelectedVendors={setSelectedVendors}
                  setVendorInputValue={(value) => {
                    setVendorInputValue(value);
                  }}
                  setIsBlocking={() => {}}
                  placeholder="Buscar vendedor"
                  disabled
                />
              </FormLayout>
            </div>
          </Collapsible>
        </div>
        <div className="px-6">
          <div
            className="w-full py-8 cursor-pointer"
            onClick={() => handleChangeCollapsible(3)}
          >
            <Button plain removeUnderline>
              <div className="flex items-center  gap-2">
                <Icon
                  source={
                    activeCollapsible === 3 ? ChevronUpMinor : ChevronDownMinor
                  }
                />
                Pago
              </div>
            </Button>
          </div>
          <Collapsible expandOnPrint open={activeCollapsible === 3}>
            <ApproveCard
              cantidad={articles.length}
              currency={currency}
              envio={{
                cantidad: handlePrecioEnvio(),
                datos: clienteEnvio,
              }}
              forCotizacion={false}
              impuesto={handleImpuesto()}
              limiteCredito={limiteCredito}
              selectedShippmentFee={selectedShippmentFee}
              setIsShowingFees={setIsShowingFees}
              setSelectedShippmentFee={setSelectedShippmentFee}
              shippingFees={shippingFees}
              showLimit={verifyLimitExceeded}
              subtotal={handleSubTotalProductos()}
              total={handleTotal()}
              weight={weight}
              orderDetails={{
                articulos: selectedArticulos.map((ar) => ({
                  articulo: ar._id,
                  notas: ar.notas || "",
                  cantidad: Number(
                    articleCount.find((art) => art.id === ar._id).cantidad
                  ),
                })),
                notas: note,
                vendorId: vendorInputValue,
              }}
              usePayment={isActivePayment}
              buttonPosition="justify-center full-button"
              buttonFullWidth
              quantityError={quantityError}
              shouldPay={clienteDB.status_microsip === "C"}
              orderData={getOrderData()}
              usePaymentsInOrders={usePaymentsInOrders}
            />
          </Collapsible>
        </div>
      </Drawer>
    </>
  );
};
export default ShoppingCart;
