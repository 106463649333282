import { SkeletonBodyText, TextStyle } from "@shopify/polaris";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  AddVariantItemModal,
  AntPagination,
  ArticuloFilterModal,
  ClienteArticulosFiltros,
  PopoverOptions,
} from "..";
import { changePage, getItemsByFilters } from "../../actions/ArticulosActions";
import { fecthFilters } from "../../actions/FiltersActions";
import { addClientInfo } from "../../actions/ShoppingCartActions";
import ArticulosFiltradosContainer from "../../containers/ArticulosFiltradosContainer/ArticulosFiltradosContainer";
import useGetElementSizes from "../../hooks/useGetElementSizes";
import useToggle from "../../hooks/useToggle";
import { getPaginationArticulos } from "../../reducers";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import useDebounce from "../../services/useDebounce";
import { getDiscounts, getShippingFees } from "../../utils/orderValidations";
import { scrollToTop } from "../../utils/windowActions";
import {
  OrderComponent,
  SortProductsChoices,
} from "../ArticuloFilterModal/ArticuloFilterModal";
import CardItemSkeleton from "../CardItemSkeleton";
import CardItem from "../common/CardItem";

export default function ProductsGridStyled({ section, mode }) {
  const router = useHistory();
  const dispatch = useDispatch();

  const [isMobile, setIsMobile] = useState(false);
  const [sheetActive, toggleSheetActive] = useToggle();
  const { properties } = section;
  const { measuredRef, width } = useGetElementSizes();
  const [isFiltersOpen, toggleFiltersOpen] = useToggle();

  const [isVariantModalOpen, toggleVariantModal] = useToggle();
  const [variantSelected, setVariantSelected] = useState(null);

  const rangeRef = useRef();

  const [orden, setOrder] = useState(["1"]);
  const [filters, setFilters] = useState([]);
  const [articleOptions, setArticleOptions] = useState([]);
  const [maxPrice, setMaxPrice] = useState(0);
  const [orderSelected, setOrderSelected] = useState(["nombre"]);
  const [rangeValue, setRangeValue] = useState([0, maxPrice]);
  const [marcasFilter, setMarcasFilter] = useState([]);
  const [filterOptionsSelected, setFilterOptionsSelected] = useState([]);
  const [filtersSelected, setFiltersSelected] = useState([]);
  const [lineaArticulo, setLineaArticulo] = useState([]);
  const [optionsFilters, setOptionsFilters] = useState({
    useBrand: false,
    usePrice: false,
    useProductType: false,
  });

  const [clienteDB, setCliente] = useState(null);
  const [impuestoDB, setImpuestoDB] = useState(null);
  const [descuentoMaximo, setPorMaximo] = useState();
  const [descuentoVolumen, setPorVolumen] = useState();
  const [descuentoPromocion, setPorPromosGen] = useState();
  const [showStock, setShowStock] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currencies, setCurrencies] = useState();
  const [isLoadingCurrencies, setIsLoadingCurrencies] = useState(true);

  const pagination = useSelector(getPaginationArticulos);

  useEffect(() => {
    setIsMobile(width <= 768);
  }, [width]);

  useEffect(() => {
    rangeRef.current = rangeValue;
  });
  const debounceRangeValue = useDebounce(rangeValue, 900);

  useEffect(() => {
    ApiServiceComercios.obtenerPreferenciasComercio().then(
      ({ preferencia }) => {
        setShowStock(preferencia?.mostrarExistenciaProducto);
      }
    );
  }, []);

  useEffect(() => {
    function fetchPedidoDatos() {
      ApiServiceComercios.obtenerPedidoDatos().then(
        ({
          ok,
          cliente,
          descuentos,
          impuesto,
          isActivePayment,
          usePaymentsInOrders,
        }) => {
          if (ok) {
            let currency = cliente.moneda;

            let shippingFees = getShippingFees(
              cliente?.envio?.shippingFees,
              currency
            );
            setCliente(cliente);
            setImpuestoDB(impuesto);

            const { promos, maximos, volumenes, productsDiscount, priceToUse } =
              getDiscounts(descuentos, cliente);
            setPorPromosGen(promos);
            setPorVolumen(volumenes);
            setPorMaximo(maximos);

            dispatch(
              addClientInfo({
                limiteCredito: cliente?.limite_credito,
                clienteEnvio: cliente?.envio,
                impuesto,
                moneda: cliente?.moneda,
                customerData: cliente,
                shippingFees,
                discounts: productsDiscount,
                priceToUse,
                isActivePayment,
                vendedor: cliente?.vendedor,
                status_microsip: cliente?.status_microsip,
                usePaymentsInOrders,
              })
            );
          }
        }
      );

      ApiServiceComercios.getCurrencies()
        .then(({ monedas }) => {
          const currenciesItems = monedas.map(
            ({ claveFiscal, _id, ...props }) => ({
              label: claveFiscal,
              value: _id,
              ...props,
            })
          );

          setCurrencies(currenciesItems);
        })
        .finally(() => setIsLoadingCurrencies(false));
    }
    fetchPedidoDatos();
  }, [dispatch]);

  useEffect(() => {
    dispatch(fecthFilters())
      .then((response = {}) => {
        const {
          filters = [],
          optionsFilters = {},
          articleOptions = [],
          maxPrice = 0,
        } = response;

        setFilters(filters);
        setOptionsFilters((optFilt) => ({ ...optFilt, ...optionsFilters }));
        setArticleOptions(articleOptions);
        setMaxPrice(maxPrice);
        setRangeValue([0, maxPrice]);
      })
      .finally(() => setIsLoading(false));
  }, [dispatch]);

  useEffect(() => {
    function fetchArticulos() {
      dispatch(
        getItemsByFilters({
          sort: orderSelected[0],
          existencia: 0,
          order: orden[0],
          linea: JSON.stringify(lineaArticulo),
          filters: JSON.stringify(filtersSelected),
          marcas: JSON.stringify(marcasFilter),
          variants: JSON.stringify(
            filterOptionsSelected.map((i) => i.split(" (")[0].trim())
          ),
        })
      );
    }
    fetchArticulos();
  }, [
    dispatch,
    orderSelected,
    orden,
    lineaArticulo,
    filtersSelected,
    marcasFilter,
    filterOptionsSelected,
    debounceRangeValue,
    pagination.currentPage,
  ]);

  function handleChangeStates(value, setState) {
    resetPagination();
    setState(value);
  }

  function resetPagination() {
    // dispatch(resetPage());
  }

  function handleFilterChange(selected) {
    setFiltersSelected(selected);
  }
  function handleFilterOptionsChange(selected) {
    setFilterOptionsSelected(selected);
  }

  const getStyles = (field) => {
    const styles = {
      container: {
        gridTemplateColumns: `repeat(${
          isMobile
            ? properties.rowsNumberInMobile
            : properties.rowsNumberDesktop
        }, minmax(0, 1fr))`,
      },
    };
    return styles[field];
  };

  function onToggleVariantModal(_id = "") {
    setVariantSelected(_id);
    toggleVariantModal();
  }

  return (
    <div className="flex w-full h-auto" ref={measuredRef}>
      {sheetActive && (
        <ArticuloFilterModal
          handleOrdenChange={(value) => handleChangeStates(value, setOrder)}
          handleOrderSelectedChange={(value) =>
            handleChangeStates(value, setOrderSelected)
          }
          isOpen={sheetActive}
          onAction={toggleSheetActive}
          toggleSheetActive={toggleSheetActive}
          orden={orden}
          orderSelected={orderSelected}
        />
      )}

      {isVariantModalOpen && (
        <AddVariantItemModal
          canShowStock={showStock}
          isOpen={isVariantModalOpen}
          onClose={toggleVariantModal}
          id={variantSelected}
          almacenId={clienteDB?.almacen?.id}
          discounts={{
            descuentoPromocion,
            descuentoVolumen,
            descuentoMaximo,
          }}
          showTax={impuestoDB?.mostrarPrecioConImpuesto}
        />
      )}
      <div
        className="flex-col w-full py-10 bg-white"
        style={{
          display: "flex",
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <ArticulosFiltradosContainer>
          {({
            articulos,
            isLoading: isLoadingArticulos,
            totalArticles,
            productTypes,
            brands,
            tags,
            options,
          }) => {
            return (
              <>
                <div>
                  {isLoading ? (
                    <div className="m-4" style={{ minWidth: 200 }}>
                      <SkeletonBodyText lines={5} />
                    </div>
                  ) : (
                    <div className="flex justify-between items-center mb-6">
                      <ClienteArticulosFiltros
                        tags={tags}
                        options={options}
                        brands={brands}
                        productTypes={productTypes}
                        filters={filters}
                        articleOptions={articleOptions}
                        optionsFilters={optionsFilters}
                        handleFilterChange={handleFilterChange}
                        selected={filtersSelected}
                        handleLineaArticuloChange={setLineaArticulo}
                        lineaArticulo={lineaArticulo}
                        collapseOnEnter={false}
                        marcasSelected={marcasFilter}
                        isOpen={isFiltersOpen}
                        toggleActive={toggleFiltersOpen}
                        filterOptionsSelected={filterOptionsSelected}
                        handleFilterOptionsChange={handleFilterOptionsChange}
                        maxPrice={maxPrice}
                        rangeValue={rangeValue}
                        setRangeValue={setRangeValue}
                        handleMarcasFilterChange={(value) =>
                          handleChangeStates(value, setMarcasFilter)
                        }
                        mode="inline"
                      />
                      <div className="flex flex-row">
                        <div className="flex">
                          <p>
                            Ordenar por:{" "}
                            {
                              SortProductsChoices.find(
                                (choice) => choice.value === orderSelected[0]
                              )?.label
                            }
                          </p>
                          <PopoverOptions
                            title="Ordenar"
                            useTitleInButton={false}
                            useClearButton={false}
                            // handleClear={}
                          >
                            <OrderComponent
                              handleOrdenChange={(value) =>
                                handleChangeStates(value, setOrder)
                              }
                              handleOrderSelectedChange={(value) =>
                                handleChangeStates(value, setOrderSelected)
                              }
                              orden={orden}
                              orderSelected={orderSelected}
                            />
                          </PopoverOptions>
                        </div>
                        <TextStyle variation="subdued">
                          {articulos.length} artículos de {totalArticles}
                        </TextStyle>
                      </div>
                    </div>
                  )}
                </div>
                {isLoadingCurrencies || isLoadingArticulos ? (
                  <CardItemSkeleton
                    items={
                      (isMobile
                        ? properties.rowsNumberInMobile
                        : properties.rowsNumberDesktop) + 2
                    }
                  />
                ) : (
                  <>
                    <div
                      className="grid gap-x-4 gap-y-7 collection-container self-center"
                      style={{ ...getStyles("container") }}
                    >
                      {articulos.map((product, key) => (
                        <CardItem
                          mode={mode}
                          key={key}
                          styleCard="OnlineStore"
                          discounts={{
                            descuentoPromocion,
                            descuentoVolumen,
                            descuentoMaximo,
                          }}
                          itemData={product}
                          almacenId={clienteDB?.almacen?.id}
                          clientCurrency={clienteDB?.moneda}
                          currencies={currencies}
                          showTax={impuestoDB?.mostrarPrecioConImpuesto}
                          onSelectVariant={onToggleVariantModal}
                          canShowStock={showStock}
                          onClickImage={() =>
                            router.push({
                              pathname: `/products/${product.handle}`,
                              state: {
                                almacenId: clienteDB?.almacen?.id,
                                discounts: {
                                  descuentoPromocion,
                                  descuentoVolumen,
                                  descuentoMaximo,
                                },
                                showTax: impuestoDB?.mostrarPrecioConImpuesto,
                              },
                            })
                          }
                          properties={properties}
                        />
                      ))}
                    </div>
                    <div className="flex justify-center py-6 pagination px-10">
                      <AntPagination
                        total={pagination.total}
                        current={pagination.currentPage + 1}
                        pageSize={pagination.sizePerPage}
                        onChange={(page) => {
                          dispatch(changePage(page - 1));
                          scrollToTop();
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            );
          }}
        </ArticulosFiltradosContainer>
      </div>
    </div>
  );
}
