import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "antd";
import { Icon, TextStyle, Tooltip } from "@shopify/polaris";
import {
  CircleInformationMajor,
  PackageMajor,
  ShipmentMajor,
  TickMinor,
  MobileCancelMajor,
} from "@shopify/polaris-icons";
import parse from "html-react-parser";
import moment from "moment";
import { NumberFormat } from "../../utils/Formats";
import "./SectionsStyled.css";
import { addShoppingCartArticles } from "../../actions/ShoppingCartActions";
import {
  getMaxSelectabledProducts,
  getProductValidations,
} from "../../utils/orderValidations";
import { getDiscountsShoppingCart } from "../../reducers";
import { getImageVideoUrl } from "../../utils/productValidators";

export default function ItemDetailStyled({
  properties,
  itemData: itemDataD,
  almacenId,
  discounts,
  showTax,
  onChangeVariant,
  setQuantityError = () => {},
  quantityError,
  canShowStock,
  clientCurrency = {
    id: 1,
    nombre: "Moneda nacional",
    claveFiscal: "MXN",
    simbolo: "$",
    tipoCambio: 1,
  },
  currencies = [],
}) {
  const dispatch = useDispatch();
  const { discounts: discountsForProduct = [], priceToUse = null } =
    useSelector(getDiscountsShoppingCart);
  const onChangeOptionRef = useRef(null);

  const [itemData, setItemData] = useState({
    ...itemDataD,
    existencia: almacenId
      ? itemDataD?.existencia.filter(
          (i) => Number(i.almacen_id) === Number(almacenId)
        )
      : itemDataD?.existencia,
  });
  const minimum = useRef(itemData.minimum ? itemData.minimum : 1);
  const factorVenta = useRef(itemData.factor_venta ? itemData.factor_venta : 1);
  const [percentage, setPercentage] = useState(0);
  const [precio, setPrecio] = useState(
    itemData.precios && itemData.precios[0] ? itemData.precios[0].precio : 0
  );
  const [quantity, setQuantity] = useState(String(minimum.current));
  const [variantSelected, setVariantSelected] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    option5: "",
    option6: "",
    option7: "",
    option8: "",
    option9: "",
    option10: "",
  });
  const [tooltipVisible, setTooltipVisible] = useState(true);
  const [fieldsError, setFieldsError] = useState(null);
  const [precioEmpresa, setPrecioEmpresa] = useState();
  const [currency, setCurrency] = useState();

  const { descuentoPromocion, descuentoVolumen, descuentoMaximo } = discounts;

  useEffect(() => {
    onChangeOptionRef.current = onChangeOption;
  });

  useEffect(() => {
    onChangeOptionRef.current();
  }, []);

  const getCurrencyConvertion = useCallback(
    (price) => {
      if (precioEmpresa && currency) {
        const moneda = precioEmpresa?.moneda;
        const tipoCambio = currency?.tipoCambio;

        if (moneda) {
          if (clientCurrency.claveFiscal === "MXN") {
            return price * tipoCambio;
          } else if (moneda === "MXN") {
            return price / tipoCambio;
          } else {
            return price;
          }
        }
        return 0;
      }
      return 0;
    },
    [clientCurrency.claveFiscal, currency, precioEmpresa]
  );

  function onChangeOption(value, option) {
    let {
      existencia = [],
      imagen = [],
      precios,
      unidadmed = "",
      nombre = "",
      _id,
      linea_articulo = "",
      options = [],
      variants = [],
      isParent,
      impuestos = [],
      sku = "",
    } = itemData;

    // VALIDATION FOR VARIANTS
    if (isParent) {
      let firstOptions = {};

      if (value && option) {
        firstOptions = { ...variantSelected };
        firstOptions[option] = value;
      } else {
        options.forEach(
          (i, idx) => (firstOptions[`option${idx + 1}`] = i.values[0])
        );
      }

      setVariantSelected(firstOptions);

      let matches = [];

      for (const key in firstOptions) {
        if (Object.hasOwnProperty.call(firstOptions, key)) {
          const element = firstOptions[key];
          let matched = [];
          if (matches.length === 0) {
            matched = variants.filter(
              (i) => i.hasOwnProperty(key) && i[key] === element
            );
          } else {
            matched = matches.filter(
              (i) => i.hasOwnProperty(key) && i[key] === element
            );
          }
          matches = matched;
        }
      }

      const {
        variantId: {
          _id: v_Id = "",
          nombre: vNombre = "",
          existencia: vExistencia = [],
          imagen: vImagen = [],
          precios: vPrecios = [],
          unidadmed: vUnidadmed = "",
          linea_articulo: vLinea_articulo = "",
          impuestos: vImpuestos = [],
          sku: vSku = "",
        },
      } = matches[0];

      nombre = vNombre;
      existencia = vExistencia;
      imagen = vImagen;
      precios = vPrecios;
      unidadmed = vUnidadmed;
      linea_articulo = vLinea_articulo;
      impuestos = vImpuestos;
      _id = v_Id;
      sku = vSku;

      existencia = almacenId
        ? existencia.filter((i) => Number(i.almacen_id) === Number(almacenId))
        : existencia;

      setItemData((s) => ({
        ...s,
        _id,
        id: _id,
        nombre: nombre,
        existencia: existencia,
        imagen: imagen,
        precios: precios,
        unidadmed: unidadmed,
        impuestos: impuestos,
        sku: sku,
      }));

      onChangeVariant(vImagen);
    } else {
      onChangeVariant(imagen);
    }

    const { percentDefault, price } = getProductValidations({
      product: {
        _id,
        precios,
        linea_articulo,
      },
      descMaximos: descuentoMaximo,
      descPromoAplicada: descuentoPromocion,
      descVolumenAplicada: descuentoVolumen,
      discounts: discountsForProduct,
      priceToUse,
    });

    setPercentage(percentDefault);
    setPrecio(price);

    // Valida si el articulo tiene un mínimo y un factor de venta
    if (
      !itemData.minimum ||
      (itemData.minimum === 1 && itemData?.factor_venta === 1)
    ) {
      setTooltipVisible(false);
    }

    const precioEmpresa =
      precios.find(
        ({ precio_empresa_id }) => precio_empresa_id === priceToUse
      ) || precios.find(({ precio_empresa_id }) => precio_empresa_id === 42);

    setPrecioEmpresa(precioEmpresa);

    const currency = currencies.find(
      ({ label }) => label === precioEmpresa?.moneda
    );

    setCurrency(currency);
  }

  const quantityValidators = useCallback(
    (value) => {
      if (value < minimum.current) {
        setFieldsError(`Mínimo ${minimum.current}`);
        setQuantityError(true, itemData._id);
        return;
      }

      if (value > itemData.maximum) {
        if (itemData.maximum && itemData.maximum > 0) {
          setFieldsError(`Máximo ${itemData.maximum}`);
          setQuantityError(true, itemData._id);
        } else {
          setFieldsError("");
          setQuantityError(false, itemData._id);
        }
        if (value % factorVenta.current !== 0) {
          setFieldsError(`Incrementos de ${factorVenta.current}`);
          setQuantityError(true, itemData._id);
        }
        return;
      }

      if (value % factorVenta.current !== 0) {
        setFieldsError(`Incrementos de ${factorVenta.current}`);
        setQuantityError(true, itemData._id);
        return;
      }

      setFieldsError("");
      setQuantityError(false, itemData._id);
    },
    [itemData._id, itemData.maximum, setQuantityError]
  );

  function handleFieldChange(value) {
    setQuantity(value);
    quantityValidators(value);
  }

  function getDiscount() {
    let discount =
      percentage > 0 ? precio - precio * (percentage / 100) : precio;
    return discount;
  }

  function validatePriceWithTax() {
    let price = getDiscount();
    let impuestos = itemData.impuestos?.filter((j) => j.porcentaje > 0) || [];
    let summary = 0;
    impuestos.forEach((j) => (summary = summary + 1 * (j.porcentaje / 100)));
    const tax = 1 + summary;
    price = showTax ? price * tax : price;
    price = getCurrencyConvertion(price);
    price = Math.round((price + Number.EPSILON) * 100) / 100;
    return Number(price || 0).toLocaleString("en", NumberFormat);
  }

  function getPercentage() {
    // Porcentaje de descuento, sólo para mostrarlo al cliente en la interfaz
    let percent = (1 - getDiscount() / precio) * 100;
    return Math.round((percent + Number.EPSILON) * 100) / 100;
  }

  function validatePriceWithTaxMain() {
    let price = precio;
    let impuestos = itemData.impuestos?.filter((j) => j.porcentaje > 0) || [];
    let summary = 0;
    impuestos.forEach((j) => (summary = summary + 1 * (j.porcentaje / 100)));
    const tax = 1 + summary;
    price = showTax ? price * tax : price;
    price = getCurrencyConvertion(price);
    price = Math.round((price + Number.EPSILON) * 100) / 100;
    return Number(price || 0).toLocaleString("en", NumberFormat);
  }

  const onBlurTextField = (value) => {
    quantityValidators(value);

    const existencia = itemData.existencia.reduce(
      (prev, current) => prev + current.existencia,
      0
    );

    if (String(quantity) === "" || Number(quantity) <= 0) {
      setQuantity("1");
      return;
    }

    if (existencia > 0 && !itemData.continuar_vendiendo) {
      if (Number(quantity) > existencia) setQuantity(String(existencia));
      return;
    }
  };

  function onAddArticle() {
    const itemToAdd = {
      article: itemData,
      cantidad: Number(quantity),
      descuentoMaximo,
      descuentoVolumen,
      descuentoPromocion,
      showTax,
    };

    notification.open({
      message: <TextStyle>Producto agregado en tu pedido</TextStyle>,
      description: (
        <div className="flex flex-row mt-4">
          <img
            alt=""
            style={{ width: 70, height: 70 }}
            src={
              getImageVideoUrl(itemData?.imagen, "image", false) ||
              "/Default Photo.png"
            }
          />
          <div className="flex flex-col ml-6">
            <TextStyle>{itemData.nombre}</TextStyle>
            <TextStyle>
              {itemData.isParent ? itemData.sku || "" : itemData.clave}
            </TextStyle>
          </div>
        </div>
      ),
      duration: 1.5,
      icon: <Icon source={TickMinor} />,
      closeIcon: <Icon source={MobileCancelMajor} />,
    });
    setQuantity("1");
    dispatch(addShoppingCartArticles(itemToAdd));
  }

  const getStyles = (field) => {
    const styles = {
      "information-container": {
        color: properties.textColor,
      },
      "add-button": {
        backgroundColor: properties.buttonColor,
        color: properties.textButtonColor,
        fontSize: 16,
      },
    };
    return styles[field];
  };

  let {
    existencia = [],
    isParent = false,
    continuar_vendiendo,
    options = [],
    nombre = "",
    descripcion = "",
    marca = "",
    sku = "",
  } = itemData;

  return (
    <div
      className="flex-1 ml-4"
      style={{ ...getStyles("information-container") }}
    >
      {properties.showBrand && marca && (
        <p className="product-brand-text mb-4 mt-2">{marca}</p>
      )}
      <p className="product-title mb-4">{nombre}</p>
      {properties.showKey && (
        <p className="product-brand-text mb-4">SKU: {sku}</p>
      )}
      <div className="flex flex-row items-center mb-8">
        {getPercentage() > 0 ? (
          <>
            <div className="line-through">
              <p className="product-brand-text mr-10">
                ${validatePriceWithTaxMain()}
              </p>
            </div>
            {properties.showDisccount && (
              <div
                className="mr-10 bg-white right-0"
                style={{
                  backgroundColor: "#1C2260",
                  color: "white",
                  padding: "4px 12px",
                }}
              >
                {getPercentage()}%
              </div>
            )}
          </>
        ) : null}
        <p
          className={`product-brand-text ${
            getPercentage() > 0 ? "text-red-500" : ""
          }`}
        >
          ${validatePriceWithTax()}
        </p>
      </div>
      {isParent && (
        <div className="flex flex-col">
          {options.map((i, idx) => (
            <div className="flex flex-col mb-10" key={idx}>
              <p className="product-brand-text mb-6">{i.name}</p>
              <select
                key={idx}
                className="p-10 border border-gray-300 bg-white"
                value={variantSelected[`option${idx + 1}`]}
                onChange={({ target: { value } }) =>
                  onChangeOption(value, `option${idx + 1}`)
                }
                style={{ fontSize: 20 }}
              >
                {i.values.map((j, key) => (
                  <option key={key} value={j}>
                    {j}
                  </option>
                ))}
              </select>
            </div>
          ))}
        </div>
      )}
      <div className="flex flex-row items-center mb-8">
        <div className="bg-white custom-input-number border border-gray-300">
          <button
            className="quantity-button quantity-substract"
            onClick={() => handleFieldChange(Number(quantity) - 1)}
          >
            -
          </button>
          <input
            type="number"
            className="custom-item-button text-center"
            style={{ fontSize: 20, height: "100%" }}
            min={1}
            max={getMaxSelectabledProducts(
              existencia,
              itemData?.maximum,
              continuar_vendiendo
            )}
            value={String(quantity)}
            onChange={({ target: { value } }) => handleFieldChange(value)}
            onBlur={() => onBlurTextField(Number(quantity))}
          />
          {fieldsError && (
            <TextStyle variation="strong">{fieldsError}</TextStyle>
          )}
          <button
            className="quantity-button quantity-plus"
            onClick={() => handleFieldChange(Number(quantity) + 1)}
          >
            +
          </button>
        </div>
        {tooltipVisible && (
          <div className="tooltip-space cursor-pointer">
            <Tooltip
              content={
                <>
                  <p>Mínimo: {minimum.current}</p>
                  <p>
                    Máximo:{" "}
                    {itemData.maximum === 0 ? "Ilimitado" : itemData.maximum}
                  </p>
                  <p>Factor: {factorVenta.current}</p>
                </>
              }
              preferredPosition="mostSpace"
            >
              <Icon source={CircleInformationMajor} color="subdued" />
            </Tooltip>
          </div>
        )}
      </div>
      <button
        className="py-8 w-full mb-8"
        style={{ ...getStyles("add-button") }}
        disabled={Number(validatePriceWithTaxMain()) === 0 || quantityError}
        onClick={onAddArticle}
      >
        Agregar
      </button>
      <div className="flex flex-col">
        {existencia
          .filter((i) =>
            almacenId ? String(i.almacen_id) === String(almacenId) : true
          )
          .map((i, index) => (
            <div className="flex" key={index}>
              <div className="mr-6">
                <Icon source={PackageMajor} />
              </div>
              <p className="product-brand-text mb-6" key={index}>
                {i?.existencia > 0 ? (
                  <TextStyle>
                    {canShowStock ? `${i?.existencia} ` : "Disponible "}
                    en
                  </TextStyle>
                ) : (
                  <TextStyle>Bajo Pedido en</TextStyle>
                )}
                <TextStyle variation="strong"> {i?.nombre_almacen}</TextStyle>
              </p>
            </div>
          ))}
        {properties.showDelivery && (
          <div className="flex">
            <div className="mr-6">
              <Icon source={ShipmentMajor} />
            </div>
            <p className="product-brand-text mb-6">
              <TextStyle>Entrega antes del</TextStyle>
              <TextStyle variation="strong">
                {" "}
                {moment()
                  .add(Number(properties.deliveryDays), "day")
                  .format("DD [de] MMMM")}
              </TextStyle>
            </p>
          </div>
        )}
      </div>
      <div className="dangerous-html">
        {parse(descripcion, {
          htmlparser2: { recognizeCDATA: true },
          trim: false,
        })}
      </div>
    </div>
  );
}
